//
import { v4 as uuid } from 'uuid';
import { IWidget } from 'src/types/product';
import { getDesc, getValue } from '../_langWidgets';

// ----------------------------------------------------------------------

export const CustomWidget: IWidget = {
  id: uuid(),
  name: 'CustomWidget',
  order: 0,
  drop: 0,
  category: 'Basic',
  open: true,
  label: {
    id: 'customwidget',
    name: 'Custom Widget',
    color: 'black',
  },
  childs: [
    {
      id: 'Head',
      open: true,
      show: true,
      attributes: {
        '1': {
          feId: 'WidgetHeadline_CustomWidget',
          change: false,
          translate: true,
          data: false,
          valueType: 'value',
          name: getDesc('WidgetHeadline_CustomWidget'),
          value: getValue('WidgetHeadline_CustomWidget'),
        },
      },
    },
  ],
  style: {
    objects: {
    },
  },
};
