import { v4 as uuid } from 'uuid';
import { IWidget } from 'src/types/product';
import { Heading } from './components/_heading';
import { Subheading } from './components/_subheading';
import { Textblock } from './components/_textblock';
import { ActionButton } from './components/_actionButton';
import { ShowcaseCarousel } from './components/_showcaseCarousel';
import { ShowcaseImage } from './components/_showcaseImage';
import { ShowcaseVideo } from './components/_showcaseVideo';
import { Document } from './components/_document';

// Create base components array
export const _components: IWidget[] = [
  Heading,
  Subheading,
  Textblock,
  ActionButton,
  ShowcaseCarousel,
  ShowcaseImage,
  ShowcaseVideo,
  Document,
];

function cloneWithNewIds(components: IWidget[]): IWidget[] {
  return components.map((component) => ({
    ...component,
    id: uuid(),
    childs: component.childs.map((child) => ({
      ...child,
      id: uuid(), // Assign new ID to each child
      attributes: Object.entries(child.attributes).reduce<Record<string, any>>(
        (acc, [key, attr]) => {
          acc[key] = {
            ...attr,
            feId: uuid(), // Generate new feId
            value: Array.isArray(attr.value) ? [...attr.value] : attr.value, // Safely copy value
          };
          return acc;
        },
        {}
      ),
    })),
    style: {
      ...component.style,
      objects: {
        ...component.style.objects,
      },
    },
  }));
}

// Generate custom components with unique IDs
export const _customComponents: IWidget[] = cloneWithNewIds(_components);