//
import { IDesc, ILangWidget, IValue } from 'src/types/product';
import { LangFooter } from './langWidgets/_langFooter';
import { LangProductHead } from './langWidgets/_langProductHead';
import { LangProductInfo } from './langWidgets/_langProductInfo';
import { LangRebuy } from './langWidgets/_langRebuy';
import { LangCertificates } from './langWidgets/_langCertificates';

import { LangHeading } from './langComponents/_langHeading';
import { LangSubheading } from './langComponents/_langSubheading';
import { LangTextblock } from './langComponents/_langTextblock';

import { LangActionButton } from './langComponents/_langActionButton';
import { LangDocument } from './langComponents/_langDocument';
import { LangShowcaseCarousel } from './langComponents/_langShowcaseCarousel';
import { LangShowcaseImage } from './langComponents/_langShowcaseImage';
import { LangShowcaseVideo } from './langComponents/_langShowcaseVideo';
import { LangCustomWidget } from './langWidgets/_langCustomWidget';
import { LangSustainability} from './langWidgets/_langSustainability';
import { LangOthers } from './langWidgets/_langOther';

export const _langWidgets: ILangWidget[] = [
  // Widgets
  LangFooter,
  LangProductHead,
  LangProductInfo,
  LangRebuy,
  LangCertificates,
  LangCustomWidget,
  LangSustainability,
  LangOthers,
  // Components
  LangHeading,
  LangSubheading,
  LangTextblock,

  LangActionButton,
  LangDocument,
  LangShowcaseCarousel,
  LangShowcaseImage,
  LangShowcaseVideo,
];

export function getDesc(feId: string) {
  let description: IDesc[] = []; // Initialize the description variable
  _langWidgets.forEach((widget) => {
    widget.forEach((attribute) => {
      if (attribute.feId === feId && attribute.desc) {
        description = attribute.desc;
      }
    });
  });
  return description; // Return the description or null if not found
}

export function getValue(feId: string) {
  let value: IValue[] = []; // Initialize the value with a default value
  _langWidgets.forEach((widget) => {
    widget.forEach((attribute) => {
      if (attribute.feId === feId && attribute.value) {
        value = attribute.value;
      }
    });
  });
  return value; // Return the found value or the default one
}
