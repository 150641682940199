import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import LabelLayout from 'src/layouts/label';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------
const SavedLabelPage = lazy(() => import('src/pages/savedLabel'));

// ----------------------------------------------------------------------

export const savedLabelRoutes = [
  {
    path: 's',
    element: (
      <AuthGuard>
        <Suspense fallback={<LoadingScreen />}>
          <Outlet />
        </Suspense>
      </AuthGuard>
    ),
    children: [
      {
        path: ':labelId',
        element: (
          <LabelLayout>
            <SavedLabelPage />
          </LabelLayout>
        ),
      },
    ],
  },
];
