import { ILangWidget } from 'src/types/product';

export const LangRebuy: ILangWidget = [
  {
    feId: 'GoToTop',
    desc: [
      { lang: 'en', val: 'Go to top' },
      { lang: 'de', val: 'Nach oben' },  // Assuming the German translation
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'ShowContactMail',
    desc: [
      { lang: 'en', val: 'Show Contact Mail' },
      { lang: 'de', val: 'Kontakt-Mail anzeigen' },  // Assuming the German translation
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'ContactMail',
    desc: [
      { lang: 'en', val: 'Contact Mail' },
      { lang: 'de', val: 'Kontakt-Mail' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'ShowRebuy',
    desc: [
      { lang: 'en', val: 'Show Rebuy' },
      { lang: 'de', val: 'Rebuy anzeigen' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'HoverButtonProductURL',
    desc: [
      { lang: 'en', val: 'URL to Product in Shop' },
      { lang: 'de', val: 'URL zum Produkt im Shop' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'DiscountCode',
    desc: [
      { lang: 'en', val: 'Discount Code' },
      { lang: 'de', val: 'Rabattcode' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'DiscountCodeDescription',
    desc: [
      { lang: 'en', val: 'Discount Code Description' },
      { lang: 'de', val: 'Rabattcode-Beschreibung' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
];