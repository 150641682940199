//
import { IWidget } from 'src/types/product';
import { FooterWidget } from './widgets/_footerWidget';
import { ProductHead } from './widgets/_productHead';
import { HoverButton } from './widgets/_hoverButton';
import { ProductInfo } from './widgets/_productInfo';
import { Certificates } from './widgets/_certificates';
import { CustomWidget } from './widgets/_customWidget';
import { Sustainability } from './widgets/_sustainability';
import { Patos } from './widgets/_patos';
import { Messe } from './widgets/_messe';
import { Recycling } from './widgets/_recycling';

// ----------------------------------------------------------------------

export const _widgets: IWidget[] = [
  HoverButton,
  ProductHead,
  ProductInfo,
  FooterWidget,
  Certificates,
  CustomWidget,
  Sustainability,
  Recycling,
  Patos,
  Messe
];
