//
import { v4 as uuid } from 'uuid';
import { IWidget } from 'src/types/product';
import { getDesc, getValue } from '../_langWidgets';

// ----------------------------------------------------------------------

export const Certificates: IWidget = {
  id: uuid(),
  name: 'Certificates',
  order: 0,
  drop: 0,
  category: 'Basic',
  open: false,
  label: {
    id: 'certificates',
    name: 'Certificates',
    color: 'black',
  },
  childs: [
    {
      id: 'Head',
      open: true,
      show: true,
      attributes: {
        '1': {
          feId: 'Certificate_BioBayern',
          change: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('Certificate_BioBayern'),
          value: getValue('Certificate_BioBayern'),
        },
        '2': {
          feId: 'Certificate_Demeter',
          change: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('Certificate_Demeter'),
          value: getValue('Certificate_Demeter'),
        },
        '3': {
          feId: 'Certificate_Naturland',
          change: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('Certificate_Naturland'),
          value: getValue('Certificate_Naturland'),
        },
        '4': {
          feId: 'Certificate_Bioland',
          change: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('Certificate_Bioland'),
          value: getValue('Certificate_Bioland'),
        },
        '5': {
          feId: 'Certificate_Bml',
          change: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('Certificate_Bml'),
          value: getValue('Certificate_Bml'),
        },
      },
    },
  ],
  style: {
    objects: {
      '1': {
        name: '',
        value: [''],
      },
    },
  },
};
