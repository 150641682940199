//
import { v4 as uuid } from 'uuid';
import { IWidget } from 'src/types/product';
import { getDesc, getValue } from '../_langWidgets';

// ----------------------------------------------------------------------

export const Textblock: IWidget = {
  id: uuid(),
  name: 'Textblock',
  order: 0,
  drop: 0,
  category: 'Text',
  open: false,
  label: {
    id: 'textblock',
    name: 'Textblock',
    color: 'black',
  },
  childs: [
    {
      id: 'Head',
      open: true,
      show: true,
      attributes: {
        '1': {
          feId: 'TextBlockContent',
          change: true,
          translate: true,
          data: true,
          valueType: 'value',
          name: getDesc('TextBlockContent'),
          value: getValue('TextBlockContent'),
        },
      },
    },
  ],
  style: {
    objects: {
      '1': {
        name: 'Orientation',
        value: ['left'],
      },
    },
  },
};
