// ----------------------------------------------------------------------

export const _design = {
  style: {
    general: {
      topButton: true,
      widgetMargin: 0,
    },
    buttons: {
      variant: '',
      size: '',
      textColor: '',
      buttonColor: '',
    },
    cards: {
      cardDesign: true,
      borderradius: 0,
      color: '',
    },
    icons: {
      showIcons: true,
      iconColor: '',
      showSubIcons: true,
      subIconColor: '',
    },
    colors: {
      primaryColor: '',
      secondaryColor: '',
      backgroundColor: '',
      generalTextColor: '',
      elementsColor: '',
    },
  },
  typography: {
    headlines: {
      font: '',
      weight: '',
      color: '',
      size: '',
    },
    paragraphs: {
      font: '',
      weight: '',
      color: '',
      size: '',
    },
    tags: {
      font: '',
      weight: '',
      color: '',
      tagColor: '',
    },
    table: {
      font: '',
      weight: '',
      color: '',
    },
  },
};
