//
import { v4 as uuid } from 'uuid';
import { IWidget } from 'src/types/product';
import { getDesc, getValue } from '../_langWidgets';

// ----------------------------------------------------------------------

export const Sustainability: IWidget = {
  id: uuid(),
  name: 'Sustainability',
  order: 0,
  drop: 0,
  category: 'Sustainability',
  open: false,
  label: {
    id: 'sustainability',
    name: 'Sustainability',
    color: 'black',
  },
  childs: [
    {
      id: 'Head',
      open: true,
      show: true,
      attributes: {
        '1': {
          feId: 'WidgetHeadline_Sustainability',
          change: false,
          translate: true,
          data: false,
          valueType: 'value',
          name: getDesc('WidgetHeadline_Sustainability'),
          value: getValue('WidgetHeadline_Sustainability'),
        },
        '2': {
          feId: 'SustainabilityDescription',
          change: false,
          translate: true,
          data: false,
          valueType: 'value',
          name: getDesc('SustainabilityDescription'),
          value: getValue('SustainabilityDescription'),
        },
      },
    },
    {
      id: 'Initiatives',
      open: false,
      show: true,
      attributes: {
        '1': {
          feId: 'WidgetHeadline_Initiatives',
          change: false,
          translate: true,
          data: false,
          valueType: 'value',
          name: getDesc('WidgetHeadline_Initiatives'),
          value: getValue('WidgetHeadline_Initiatives'),
        },
        '2': {
          feId: 'SubheadlineInitiatives',
          change: false,
          translate: true,
          data: false,
          valueType: 'value',
          name: getDesc('SubheadlineInitiatives'),
          value: getValue('SubheadlineInitiatives'),
        },
        '3': {
          feId: 'InitiativePicture',
          change: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('InitiativePicture'),
          value: getValue('InitiativePicture'),
        },
        '4': {
          feId: 'InitiativeFilename',
          change: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('InitiativeFilename'),
          value: getValue('InitiativeFilename'),
        },
        '5': {
          feId: 'InitiativeShowPicture',
          change: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('InitiativeShowPicture'),
          value: getValue('InitiativeShowPicture'),
        },
        '6': {
          feId: 'InitiativeDesc',
          change: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('InitiativeDesc'),
          value: getValue('InitiativeDesc'),
        },
      },
    },
    {
      id: 'Truecost',
      open: false,
      show: true,
      attributes: {
        '1': {
          feId: 'WidgetHeadline_Truecost',
          change: false,
          translate: true,
          data: false,
          valueType: 'value',
          name: getDesc('WidgetHeadline_Truecost'),
          value: getValue('WidgetHeadline_Truecost'),
        },
        '2': {
          feId: 'TrueCostProductDesc',
          change: true,
          translate: true,
          data: true,
          valueType: 'value',
          name: getDesc('TrueCostProductDesc'),
          value: getValue('TrueCostProductDesc'),
        },
        '3': {
          feId: 'TrueCostProductUVP',
          change: true,
          translate: true,
          data: true,
          valueType: 'value',
          name: getDesc('TrueCostProductUVP'),
          value: getValue('TrueCostProductUVP'),
        },
        '4': {
          feId: 'TrueCostProduct',
          change: true,
          translate: true,
          data: true,
          valueType: 'value',
          name: getDesc('TrueCostProduct'),
          value: getValue('TrueCostProduct'),
        },
        '5': {
          feId: 'TrueCostCompareDesc',
          change: true,
          translate: true,
          data: true,
          valueType: 'value',
          name: getDesc('TrueCostCompareDesc'),
          value: getValue('TrueCostCompareDesc'),
        },
        '6': {
          feId: 'TrueCostCompareUVP',
          change: true,
          translate: true,
          data: true,
          valueType: 'value',
          name: getDesc('TrueCostCompareUVP'),
          value: getValue('TrueCostCompareUVP'),
        },
        '7': {
          feId: 'TrueCostCompare',
          change: true,
          translate: true,
          data: true,
          valueType: 'value',
          name: getDesc('TrueCostCompare'),
          value: getValue('TrueCostCompare'),
        },
        '8': {
          feId: 'TrueCostCauses',
          change: true,
          translate: true,
          data: true,
          valueType: 'keyValue',
          name: getDesc('TrueCostCauses'),
          value: getValue('TrueCostCauses'),
        },
        '9': {
          feId: 'TrueCostStudy',
          change: true,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('TrueCostStudy'),
          value: getValue('TrueCostStudy'),
        },
        '10': {
          feId: 'TrueCostStudyName',
          change: true,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('TrueCostStudyName'),
          value: getValue('TrueCostStudyName'),
        },
      },
    },
    {
      id: 'Co2',
      open: false,
      show: true,
      attributes: {
        '1': {
          feId: 'WidgetHeadline_Co2',
          change: false,
          translate: true,
          data: false,
          valueType: 'value',
          name: getDesc('WidgetHeadline_Co2'),
          value: getValue('WidgetHeadline_Co2'),
        },
        '2': {
          feId: 'Co2Values',
          change: true,
          translate: true,
          data: true,
          valueType: 'keyValue',
          name: getDesc('Co2Values'),
          value: getValue('Co2Values'),
        },
      },
    },
    {
      id: 'Water',
      open: false,
      show: true,
      attributes: {
        '1': {
          feId: 'WidgetHeadline_Water',
          change: false,
          translate: true,
          data: false,
          valueType: 'value',
          name: getDesc('WidgetHeadline_Water'),
          value: getValue('WidgetHeadline_Water'),
        },
        '2': {
          feId: 'WaterValues',
          change: true,
          translate: true,
          data: true,
          valueType: 'keyValue',
          name: getDesc('WaterValues'),
          value: getValue('WaterValues'),
        },
      },
    },
  ],
  style: {
    objects: {
    },
  },
};
