// ----------------------------------------------------------------------

export const HEADER = {
  H_MOBILE: 30,
  H_DESKTOP: 30,
  H_DESKTOP_OFFSET: 10,
};

export const NAV = {
  W_VERTICAL: 280,
  W_MINI: 88,
};
